import React from 'react';
interface NewsletterInputProps{
    label: string;
    name: string;
    type: string;
    value: string;
    hasError: boolean;
    handleChange(e: any): void;
}

const NewsletterInput: React.FC<NewsletterInputProps> = ({label, name, type, value, hasError, handleChange}) => {
  return (
    <div className={`lp-sec11--2-form-control`}>
        <label>
            {label}
            <input 
                type={type} 
                className={`${hasError && 'has-error' }`} 
                name={name} 
                value={value} 
                onChange={handleChange} 
            />
        </label>
    </div>
  );
}

export default NewsletterInput;