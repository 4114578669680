import Logo from "../../assets/images/logo-form.svg";
import { MdClose } from "react-icons/md";

import "./termmodal.scss";

interface TermModalProps {
  onClose: () => void;
}

const TermModal: React.FC<TermModalProps> = ({ onClose }) => {
  function handleOutsideClick(event: any) {
    if (event.target.classList.contains("term-modal--bg")) {
      onClose();
    }
  }
  return (
    <>
      (
      <div className="term-modal--bg" onClick={handleOutsideClick}>
        <div className="term-modal--container">
          <div className="term-modal--wrapper2">
            <button className="term-modal--close" onClick={onClose}>
              <MdClose />
            </button>
            <img src={Logo} alt="Doar Bem" />
            <h4>TERMOS DE USO E POLÍTICA DE PRIVACIDADE E USO DE COOKIES</h4>
            <p>
              <p className="term-modal--subtitle">
                Política de Privacidade - Doar Bem
              </p>
              A sua privacidade é importante para nós. Faz parte da política da
              DoarBem respeitar a sua privacidade em relação a qualquer
              informação que possamos coletar no nosso site e em outros sites
              que possuímos e operamos. Solicitamos informações pessoais apenas
              quando realmente precisamos delas para lhe fornecer um serviço.
              Fazemos a solicitação por meios justos e legais, com o seu
              conhecimento e consentimento. Também informamos o porquê que
              estamos coletando e como a informação será usada. Apenas retemos
              as informações coletadas pelo tempo necessário para fornecer o
              serviço solicitado. Quando armazenamos dados, protegemos-os dentro
              de meios comercialmente aceitáveis ​​para evitar perdas e roubos,
              bem como acesso, divulgação, cópia, uso ou modificação não
              autorizados. Não compartilhamos informações de identificação
              pessoal publicamente ou com terceiros, exceto quando exigido por
              lei. O nosso site pode ter links para sites externos que não são
              operados por nós. Esteja ciente de que não temos controle sobre o
              conteúdo e as práticas desses sites e não podemos aceitar a
              responsabilidade por suas respectivas políticas de privacidade.
              Você é livre para recusar a nossa solicitação de informações
              pessoais, entendendo que talvez não possamos fornecer alguns dos
              serviços desejados. O uso continuado de nosso site será
              considerado como aceitação de nossas práticas em torno de
              privacidade e informações pessoais. Assim, em seu primeiro acesso,
              você poderá dar o seu aceito às nossas práticas. Nos demais
              acessos, nosso site utilizará os dados que nossas práticas já
              gravaram. Em caso de qualquer dúvida, nosso canal de comunicação é
              este: contato@doarbem.com.br. (CONFERIR SE É ESSE MESMO) Entre em
              contato conosco. Estaremos à disposição para auxiliar. Política de
              Cookies da DoarBem O que são cookies? Como é prática comum em
              quase todos os sites profissionais, este site utiliza cookies, que
              são pequenos arquivos baixados no seu computador, para melhorar
              sua experiência. É uma espécie de melhora de curto prazo. Eles
              permitem que pedaços de sua navegação sejam armazenados como
              informações que serão usadas para tornar a sua experiência em
              nosso site ainda mais agradável. Esta página descreve quais
              informações eles coletam, como as usamos e por qual razão, às
              vezes, precisamos armazenar esses cookies. Também compartilharemos
              como você pode impedir que esses cookies sejam armazenados. No
              entanto, isso pode fazer o downgrade ou “quebrar” certos elementos
              da funcionalidade do nosso site. Desativar cookies Você pode
              impedir a configuração de cookies indo em configurações do seu
              navegador (consulte a Ajuda do navegador para saber como fazer
              isso). - Conferir esse passo a passo Você também pode clicar em
              não quando o nosso site pedir a sua autorização para o uso dos
              cookies. - Tem como deixar uma opção de clicar sim ou não, logo
              que o usuário entra no site? Porém, esteja ciente de que a
              desativação de cookies afetará a funcionalidade deste site. A
              desativação de cookies geralmente resultará na desativação de
              determinadas funcionalidades e recursos deste site. Como usamos os
              Cookies Cookies relacionados à conta; Se você criar uma conta
              conosco, usaremos cookies para o gerenciamento do processo de
              inscrição e administração geral. Esses cookies geralmente serão
              excluídos quando você sair do sistema. No entanto, em alguns
              casos, eles poderão permanecer posteriormente para lembrar as
              preferências do seu site ao sair. Cookies relacionados ao login;
              Utilizamos cookies quando você está logado para que nós possamos
              lembrar dessa ação. Isso evita que você precise fazer login sempre
              que visitar uma nova página do nosso site. Esses cookies,
              normalmente, são removidos ou limpos quando você efetua logout
              para garantir que você possa acessar apenas a recursos e áreas
              restritas ao efetuar login. Cookies relacionados a boletins por
              e-mail; Este site oferece serviços de assinatura de boletim
              informativo ou e-mail e os cookies podem ser usados ​​para lembrar
              se você já está registrado e se deve mostrar determinadas
              notificações válidas apenas para usuários inscritos/não inscritos.
              Pedidos processando cookies relacionados; Este site oferece
              facilidades de comércio eletrônico ou pagamento e alguns cookies
              são essenciais para garantir que seu pedido seja lembrado entre as
              páginas, para que possamos processá-lo adequadamente. Cookies
              relacionados a pesquisas; Periodicamente, oferecemos pesquisas e
              questionários para fornecer informações interessantes, ferramentas
              úteis ou para entender nossa base de usuários com mais precisão.
              Essas pesquisas podem usar cookies para lembrar quem já participou
              numa pesquisa ou para fornecer resultados precisos após a
              alteração das páginas. Cookies relacionados a formulários; Quando
              você envia dados por meio de um formulário como os encontrados nas
              páginas de contato ou nos formulários de comentários, os cookies
              podem ser configurados para lembrar os detalhes do usuário para
              correspondência futura. Cookies de preferências do site; Para
              proporcionar uma ótima experiência neste site, fornecemos a
              funcionalidade para definir suas preferências de como esse site é
              executado quando você o usa. Para lembrar suas preferências,
              precisamos definir cookies para que essas informações possam ser
              chamadas sempre que você interagir com uma página. Cookies de
              Terceiros Em alguns casos especiais, também usamos cookies
              fornecidos por terceiros confiáveis. A seção a seguir detalha
              quais cookies de terceiros você pode encontrar por meio deste
              site. Este site usa o Google Analytics, que é uma das soluções de
              análise mais difundidas e confiáveis ​​da Web. Ele nos ajudar a
              entender como você usa o site e como podemos melhorar sua
              experiência. Esses cookies podem rastrear itens como quanto tempo
              você gasta no site e as páginas visitadas para que, assim,
              possamos continuar produzindo conteúdo atraente. Para mais
              informações sobre cookies do Google Analytics, consulte a página
              oficial do Google Analytics. As análises de terceiros são usadas
              para rastrear e medir o uso deste site, para que, assim, possamos
              continuar produzindo conteúdo atrativo. Esses cookies podem
              rastrear itens como o tempo que você passa no site ou as páginas
              visitadas, o que nos ajuda a entender como podemos melhorar o site
              para você. Periodicamente, testamos novos recursos e fazemos
              alterações sutis na maneira como o site se apresenta. Quando ainda
              estamos testando novos recursos, esses cookies podem ser usados
              ​​para garantir que você receba uma experiência consistente
              enquanto estiver no site. Utilizares isso para entendermos quais
              otimizações os nossos usuários mais apreciam. Na medida em que
              vendemos produtos, é importante entendermos as estatísticas sobre
              quantos visitantes de nosso site realmente compram e, portanto,
              esse é o tipo de dados que esses cookies rastrearão. Isso é
              importante para você, pois significa que podemos fazer previsões
              de negócios com precisão que nos permitem analizar nossos custos
              de publicidade e produtos para garantir o melhor preço possível.
              Compromisso do Usuário O usuário se compromete a fazer uso
              adequado dos conteúdos e das informações que a DoarBem oferece no
              site e com caráter enunciativo, mas não limitativo: Não se
              envolver em atividades que sejam ilegais ou contrárias à boa fé a
              à ordem pública; Não difundir propaganda ou conteúdo de natureza
              racista, xenofóbica, jogos de hoje ou de azar, qualquer tipo de
              pornografia, de apologia ao terrorismo ou contra os direitos
              humanos; Não causar danos aos sistemas físicos (hardwares) e
              lógicos (softwares) da DoarBem, de seus fornecedores ou terceiros,
              para introduzir ou disseminar vírus informáticos ou quaisquer
              outros sistemas de hardware ou software que sejam capazes de
              causar danos anteriormente mencionados. Informações finais
              Esperamos que esteja esclarecido como nosso política de
              privacidade funciona. Qualquer dúvida, entre em contato por meio
              de COLOCAR O E-MAIL DE CONTATO.
            </p>
          </div>
        </div>
      </div>
      )
    </>
  );
};

export default TermModal;
