type MaskType = (value: string) => string;

const onlyNumber: MaskType = (value) => value.replace(/\D/g, "");

const phone: MaskType = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/g, "($1) $2")
    .replace(/(\d)(\d{5})$/, "$1-$2")
    .slice(0, 15);
};

const exportedConsts = {
  onlyNumber,
  phone
}

export default exportedConsts ;