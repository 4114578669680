import React from "react";
import classes from "./Footer.module.scss";
import wave from "../../core/assets/icons/Subtract.png";
import logo from "../../core/assets/icons/logo.png";
import face from "../../core/assets/icons/facebook.svg";
import insta from "../../core/assets/icons/insta.svg";
import linkedin from "../../core/assets/icons/linkedin.svg";

export const Footer = () => {
  return (
    <footer>
      <div
        id="footer"
        className={classes.Footer__content}
        style={{ backgroundImage: `url(${wave})` }}
      >
        <div className={classes.Footer__circle}>
          <a href="#logo">
            <img src={logo} alt="logo da Doar Bem" />
          </a>
        </div>
        <div className={classes.Footer__upbottom}>
          <div className={classes.Footer__text}>
            <p>
              Buscamos <span>Excelência</span>
            </p>
            <p>
              Buscamos <span>Inovação</span>
            </p>
            <p>
              Buscamos <span>Solidariedade</span>
            </p>
          </div>
          <div className={classes.Footer__redes}>
            <p>Venha nos conhecer!</p>
            <div>
              <a
                href="https://www.linkedin.com/in/projeto-doar-bem"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="icone do linkedin" width={56} />
              </a>
              <a
                href="https://www.facebook.com/DoarBem-111930133986791"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={face} alt="icone do facebook" width={56} />
              </a>
              <a
                href="https://www.instagram.com/doar_bem/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={insta} alt="icone do instagram" width={52} />
              </a>
            </div>
          </div>
        </div>
        <div className={classes.Footer__bottom}>
          Somos uma plataforma que conecta ongs e pessoas que querem contribuir
          para uma sociedade melhor.
        </div>
      </div>
    </footer>
  );
};
