import React from "react";

import JoySantosImage from "../../../core/assets/images/joy-santos.png";
import RafaelCaldeiraImage from "../../../core/assets/images/rafael-caldeira.png";
import MariBodrasImage from "../../../core/assets/images/mari-bodras.png";
import AnaPaulaImage from "../../../core/assets/images/ana-paula.png";
import MiguelApaImage from "../../../core/assets/images/miguel-apa.png";
import FelipeTedescoImage from "../../../core/assets/images/felipe-tedesco.png";
import PriscilaHenriquesImage from "../../../core/assets/images/priscila-henriques.png";
import ClaudieliPozziImage from "../../../core/assets/images/claudieli-pozzi.png";

import KarinaRautaImage from "../../../core/assets/images/karina-rauta.png";

import MarcioDevImage from "../../../core/assets/images/Dev_Marcio.png";
import HigorDevImage from "../../../core/assets/images/Dev_Higor.png";
import RaphaelDevImage from "../../../core/assets/images/Dev_Raphael.png";
import RenanDevImage from "../../../core/assets/images/Dev_Renan.png";
import VenturaDevImage from "../../../core/assets/images/Dev_VVentura.png";




interface SlideItemProp {
  slideClass: "slide--0" | "slide--1" | "slide--2";
  title: string;
}

const SlideItem: React.FC<SlideItemProp> = ({ slideClass, title }) => {
  return (
    <div className={`lp-sec10--2-slider-item ${slideClass}`}>
      <h2 className="sec-title--2">{title}</h2>
      {slideClass === "slide--0" && (
        <div className="lp-sec10--2-team">
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={JoySantosImage} alt="Joy Santos" />
              <strong>Joy Santos</strong>
              <span>Coordenadora Geral</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={RafaelCaldeiraImage} alt="Rafael Caldeira" />
              <strong>Rafael Caldeira</strong>
              <span>Produtos</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={MariBodrasImage} alt="Mari Bodras" />
              <strong>Mari Bodras</strong>
              <span>Marketing</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={AnaPaulaImage} alt="Ana Paula" />
              <strong>Ana Paula</strong>
              <span>Gente e Gestão</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={MiguelApaImage} alt="Miguel Apa" />
              <strong>Miguel Apa</strong>
              <span>Coordenador Geral</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={FelipeTedescoImage} alt="Felipe Tedesco" />
              <strong>Felipe Tedesco</strong>
              <span>Tech Lead</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={PriscilaHenriquesImage} alt="Priscila Henriques" />
              <strong>Priscila Henriques</strong>
              <span>Design Ops</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={ClaudieliPozziImage} alt="Claudieli Pozzi" />
              <strong>Claudieli Pozzi</strong>
              <span>Jurídico</span>
            </div>
          </div>
        </div>
      )}
      {slideClass === "slide--1" && (
        <div className="lp-sec10--2-team">
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={PriscilaHenriquesImage} alt="Priscila Henriques" />
              <strong>Priscila Henriques</strong>
              <span>Design Ops</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={KarinaRautaImage} alt="Karina Rauta" />
              <strong>Karina Rauta</strong>
              <span>UX Writing/Research</span>
            </div>
          </div>
        </div>
      )}
      {slideClass === "slide--2" && (
        <div className="lp-sec10--2-team">
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={MarcioDevImage} alt="MarcioHenrique" />
              <strong>Marcio Henrique</strong>
              <span>Desenvolvedor</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={HigorDevImage} alt="HigorVital" />
              <strong>Higor Vital</strong>
              <span>Desenvolvedor</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={RaphaelDevImage} alt="RaphaelModeneze" />
              <strong>Raphael Modeneze</strong>
              <span>Desenvolvedor</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={RenanDevImage} alt="Renan Aguiar" />
              <strong>Renan Aguiar</strong>
              <span>Desenvolvedor</span>
            </div>
          </div>
          <div className="lp-sec10--2-member">
            <div className="lp-sec10--2-member-image">
              <img src={VenturaDevImage} alt="ViniciusVentura" />
              <strong>Vinícius Ventura</strong>
              <span>Desenvolvedor</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlideItem;
