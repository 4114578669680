import React, { InputHTMLAttributes, useCallback, useState } from "react";

import "./forminput.scss";
export interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  handleChange: any;
  type: string;
  mask?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  labelText,
  handleChange,
  type,
  mask,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);

  const handleFocus = useCallback(() => {
    setFocus(!focus);
  }, [focus, setFocus]);

  return (
    <label className="form-input-container">
      {labelText}
      <input
        onChange={handleChange}
        className={focus ? "focus" : ""}
        {...rest}
        required
        type={type}
        onFocus={handleFocus}
        onBlur={handleFocus}
      />
    </label>
  );
};

FormInput.defaultProps = {
  type: "text",
  labelText: undefined,
  handleChange: undefined,
};

export default FormInput;
