import React, { useCallback, useState } from "react";

import { ReactComponent as CampaignsIcon } from "../../core/assets/icons/campaigns.svg";
import { ReactComponent as CaptureIcon } from "../../core/assets/icons/capture.svg";
import { ReactComponent as CircleIcon } from "../../core/assets/icons/circle.svg";
import { ReactComponent as ConscienceIcon } from "../../core/assets/icons/conscience.svg";
import { ReactComponent as BuildingIcon } from "../../core/assets/icons/building.svg";
import { ReactComponent as DonationsIcon } from "../../core/assets/icons/donations.svg";
import { ReactComponent as LetterIcon } from "../../core/assets/icons/letter-d.svg";
import { ReactComponent as UserFilledIcon } from "../../core/assets/icons/user-filled.svg";
import { ReactComponent as UserIcon } from "../../core/assets/icons/user.svg";
import { ReactComponent as WorldIcon } from "../../core/assets/icons/world.svg";

import { ReactComponent as ProjectImage } from "../../core/assets/images/landing-page-img.svg";
import { ReactComponent as ProcessImage } from "../../core/assets/images/process.svg";
import AboutUsImage from "../../core/assets/images/about-usjpg.jpg";

import "./home.scss";
import FormModal from "../../components/FormModal";

const Home = () => {
  const [formModalOpen, setFormModalOpen] = useState(false);

  const toggleModal = useCallback(
    (open?: boolean) => {
      if (open === undefined) {
        setFormModalOpen(!formModalOpen);
      } else {
        setFormModalOpen(open);
      }
    },
    [formModalOpen]
  );

  const handleOpenFormModal = useCallback(() => {
    toggleModal(true);
  }, [toggleModal]);

  return (
    <>
      <FormModal isOpen={formModalOpen} toggleModal={toggleModal} />

      <div className="lp-container-1">
        <section id="doarbemhome" className="lp-sec1">
          <div id="doarbemhome" className="lp-title-container">
            <h1>
              Embarque na <span>Jornada</span> que vai{" "}
              <span id="lp-title-container--inovarspan">inovar</span> o processo
              de doações no Brasil
              <WorldIcon />
            </h1>
            <h2>
              Somos a DoarBem, uma plataforma que pretende conectar quem quer
              doar a quem precisa.
            </h2>
            <button type="submit" name="part" onClick={handleOpenFormModal}>
              PARTICIPE AGORA!
            </button>
          </div>
          <div className="images-container">
            <ProjectImage />
            <ProcessImage />
          </div>
        </section>

        <section id="somosmovidos" className="lp-sec2">
          <img src={AboutUsImage} alt="" />
          <div id="somosmovidos" className="lp-subtitle-container">
            <h3>
              Somos movidos pela <span>colaboração</span>
            </h3>
            <div className="lp-subtitle-container-mobile">
              <h4>
                <span>Nosso propósito</span> é conectar pessoas e transformar a
                sociedade por meio de ações sociais como a doação.
              </h4>
              <h4>
                <span>Atualmente</span>, das 300 mil ongs brasileiras, somente
                3% recebem ajuda do Governo.
              </h4>
              <h4>
                <span>O restante</span>, depende de doações voluntárias para a
                realização de projetos sociais.
              </h4>
            </div>
          </div>
        </section>

        <div className="bg-2-wrapper" id="bg-2-wrapper">
          <div id="bg-2-wrapper-background">
            <section id="propositosevalores" className="lp-sec3">
              <h2>
                Nosso propósito é ser a ponte entre <span>Doadores</span> e{" "}
                <span>Ongs</span>
              </h2>
              <h4>
                A DoarBem deseja tornar o ambiente de doações no Brasil mais
                confiável e acessível. E dessa forma, contribuir para o
                crescimento da cultura de doação no País.
              </h4>
            </section>

            <section className="lp-sec4">
              <div className="lp-campaigns-wrapper">
                <CampaignsIcon />
                <h4>Comprometimento e Ética</h4>
              </div>

              <div className="lp-middle-container">
                <div className="lp-donation-wrapper">
                  <DonationsIcon />
                  <h4>Colaboração e Inovação</h4>
                </div>

                <div className="lp-world-wrapper">
                  <WorldIcon />
                </div>

                <div className="lp-conscience-wrapper">
                  <ConscienceIcon />
                  <h4>Doação e Consciência</h4>
                </div>

                <div className="lp-white-bg">
                  <CircleIcon />
                </div>
              </div>

              <div className="lp-capture-wrapper">
                <CaptureIcon />
                <h4>Altruísmo e Compaixão</h4>
              </div>
            </section>

            <section id="objetivos" className="lp-sec5">
              <h2>
                Nosso desejo é ser um facilitador nessa troca e fomentar as
                <span> doações </span> no <span>Mundo</span>
              </h2>
            </section>

            <section className="lp-sec6">
              <div className="lp-sec6-wrapper1">
                <div className="lp-sec6-subsec1">
                  <div className="icon-wrapper">
                    <UserIcon />
                  </div>
                  <h5>
                    Sensibilizar e Conscientizar a sociedade para a importância
                    de sermos doadores
                  </h5>
                </div>

                <div className="lp-sec6-subsec2">
                  <div className="icon-wrapper">
                    <UserIcon />
                  </div>
                  <h5>
                    Nos esforçaremos para promover a inclusão digital através da
                    nossa plataforma
                  </h5>
                </div>

                <div className="lp-sec6-subsec3">
                  <div className="icon-wrapper">
                    <UserIcon />
                  </div>
                  <h5>
                    Estamos confiantes e transparecemos através do produto que
                    oferecemos.
                  </h5>
                </div>
              </div>

              <div className="lp-sec6-division"></div>

              <div className="lp-sec6-mobile">
                <div className="lp-sec6-subsec4">
                  <div className="icon-wrapper">
                    <BuildingIcon />
                  </div>
                  <h5>
                    Queremos conseguir parceiros engajados e que se identifiquem
                    com a causa do projeto
                  </h5>
                </div>

                <div className="lp-sec6-subsec5">
                  <div className="icon-wrapper">
                    <BuildingIcon />
                  </div>
                  <h5>
                    Nos empenharemos para sermos autoridade e relevante a nível
                    nacional.
                  </h5>
                </div>

                <div className="lp-sec6-subsec6">
                  <div className="icon-wrapper">
                    <BuildingIcon />
                  </div>
                  <h5>
                    Criaremos um ambiente seguro para que tanto as Ongs, quantos
                    os doadores se sintam protegidos
                  </h5>
                </div>
              </div>

              <div className="lp-sec6-wrapper2">
                <div className="lp-sec6-subsec4">
                  <h5>
                    Queremos conseguir parceiros engajados e que se identifiquem
                    com a causa do projeto
                  </h5>
                  <div className="icon-wrapper">
                    <BuildingIcon />
                  </div>
                </div>

                <div className="lp-sec6-subsec5">
                  <h5>
                    Nos empenharemos para sermos autoridade e relevante a nível
                    nacional.
                  </h5>
                  <div className="icon-wrapper">
                    <BuildingIcon />
                  </div>
                </div>

                <div className="lp-sec6-subsec6">
                  <h5>
                    Criaremos um ambiente seguro para que tanto as Ongs, quantos
                    os doadores se sintam protegidos
                  </h5>
                  <div className="icon-wrapper">
                    <BuildingIcon />
                  </div>
                </div>
              </div>
            </section>

            <section className="lp-sec7">
              <div className="letter-container">
                <LetterIcon />
              </div>

              <div className="data-container">
                <div className="user-wrapper">
                  <div className="user-data">
                    <div className="icon-container">
                      <div className="icon-wrapper">
                        <UserFilledIcon />
                        <h2>10.000</h2>
                      </div>
                    </div>
                  </div>
                  <h4>
                    Chegarmos a <span>10.000 usuários</span> ativos.
                  </h4>
                </div>

                <div className="user-wrapper">
                  <div className="user-data">
                    <div className="icon-container">
                      <div className="icon-wrapper">
                        <UserFilledIcon />
                        <h2>1.000</h2>
                      </div>
                    </div>
                  </div>
                  <h4>
                    Recebermos <span>1.000 visitas</span> semanais.
                  </h4>
                </div>

                <div className="user-wrapper">
                  <div className="user-data">
                    <div className="icon-container">
                      <div className="icon-wrapper">
                        <UserFilledIcon />
                        <h2>1.000</h2>
                      </div>
                    </div>
                  </div>
                  <h4>
                    Contribuiremos para a doação de
                    <span>1.00000 de alimentos</span>.
                  </h4>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
