import { useCookies } from "react-cookie";
import classes from "./cookie.module.scss";
import cookieimg from "../../core/assets/icons/emojione_cookie.svg";
import { useEffect, useState } from "react";

const Cookie = () => {
  const [open, setOpen] = useState(true);
  const [cookies, setCookie] = useCookies(["user"]);
  const [openCookie, setOpenCookies] = useCookies(["openCookie"]);
  function handleCookie() {
    setOpen(false);
    setOpenCookies("openCookie", true, {
      path: "/",
    });
    setCookie("user", "", {
      path: "/",
    });
  }

  useEffect(() => {
    if (openCookie.openCookie) {
      setOpen(false);
    }
  }, [openCookie.openCookie]);

  return (
    <>
      {open && (
        <div className={classes.Cookie__modal}>
          <img className={classes.Cookie__modal__img} src={cookieimg} alt="" />
          <h3 className={classes.Cookie__modal__h3}>Aqui tem Cookie :0</h3>
          <span>{cookies.user}</span>
          <p className={classes.Cookie__modal__p}>
            Usamos os <strong>cookies</strong> para garantir que oferecemos a
            melhor
            <strong> experiência</strong> em nosso site. Clique no botão{" "}
            <strong>aceitar</strong> e seja bem-vindo(a)!.
          </p>
          <button
            className={classes.Cookie__modal__button}
            onClick={handleCookie}
          >
            <p>Aceitar</p>
          </button>
        </div>
      )}
    </>
  );
};

export default Cookie;
