import React, { forwardRef } from 'react'
import FormInput, {FormInputProps} from './index'

import maskFunctions from '../../core/utils/masks'

interface NumberProps extends FormInputProps{
  mask: keyof typeof maskFunctions
}

const Number = forwardRef<HTMLInputElement, NumberProps>(
  ({ mask, onChange, ...props }) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.currentTarget.value = maskFunctions[mask]?.(event.currentTarget.value)
      onChange?.(event)
    }
    return (<FormInput onChange={handleChange} {...props} />)
  })

Number.defaultProps = {
  mask: "onlyNumber",
}

export default Number