import React, { InputHTMLAttributes, useCallback, useState } from "react";
import { MdCheck } from "react-icons/md";

import "./formcheckbox.scss";
interface FormCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  handleChange: any;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  labelText,
  handleChange,
  ...rest
}) => {
  const [checked, setChecked] = useState(false);

  const handleCheck = useCallback(() => {
    handleChange();
    setChecked(!checked);
  }, [checked, setChecked, handleChange]);

  return (
    <label className="form-checkbox-container" onClick={handleCheck}>
      <div className={checked ? "checked" : ""}>
        <MdCheck />
      </div>
      <input {...rest} type="hidden" />
      <span>{labelText}</span>
    </label>
  );
};

export default FormCheckbox;
