import React, { useCallback, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import MailchimpSubscribe from "react-mailchimp-subscribe";

import HowCanIHelpImage from "../../core/assets/images/como-posso-ajudar.png";
import AdminScreenImage from "../../core/assets/images/tela-admin.png";
import CampaignImage from "../../core/assets/images/tela-campanha.png";

import ServiceIcon from "../../core/assets/icons/icon-services.svg";
import EnvelopeIcon from "../../core/assets/icons/icon-envelope.svg";
import PeopleIcon from "../../core/assets/icons/icon-people.svg";

import { ReactComponent as WorldIcon } from "../../core/assets/icons/world.svg";
import SubscribeImage from "../../core/assets/images/subscribe.png";

import "./bodyparttwo.scss";
import SlideItem from "./SlideItem";
import NewsletterForm from "./NewsletterForm";

const BodyPartTwo: React.FC = () => {
  const postUrl = `https://doarbem.us8.list-manage.com/subscribe/post?u=926e2f97e49fdbe6b5f0a94bc&id=36c9d22ab3`;

  const [slideColor, setSlideColor] = useState("slide--0");

  const [subscribeMessage, setSubscribeMessage] = useState(false);

  const renderIndicator = useCallback(
    (
      clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
      isSelected: boolean
    ) => {
      if (isSelected) {
        return (
          <div
            onClick={clickHandler}
            className="carousel-indicator selected"
          ></div>
        );
      }

      return <div onClick={clickHandler} className="carousel-indicator"></div>;
    },
    []
  );

  const handleClickIndicator = useCallback((index) => {
    switch (index) {
      case 0:
        setSlideColor("slide--0");
        break;
      case 1:
        setSlideColor("slide--1");
        break;
      case 2:
        setSlideColor("slide--2");
        break;
    }
  }, []);

  const successForm = (statusMessage) => {
    setSubscribeMessage(statusMessage);
  };

  return (
    <div className="lp-container-2">
      <section className="lp-sec8--2">
        <div className="lp-sec8--2-wrapper1">
          <h1 className="sec-title sec-title--center">
            Como a <span>DoarBem</span> pode ajudar?
          </h1>
          <h2 className="lp-sec11--2-subtitle">
            Além de ajudar muitas organizações a conseguirem doações, queremos
            estimular a inclusão digital no terceiro setor e ser um agente da
            mudança de cultura de doações no Brasil
          </h2>
        </div>
        <div className="lp-sec8--2-wrapper2">
          <img src={HowCanIHelpImage} alt="Como posso ajudar?" />
        </div>
      </section>
      <section className={`lp-sec9--2 ${slideColor}`}>
        <div className="lp-sec9--2-bg">
        </div>
        <div className="lp-sec9--2-content">
          <div id="servicos" className="lp-sec9--2-wrapper1">
            <img src={ServiceIcon} alt="Icone Serviços" />
            <div className="lp-sec9--2-wrapper1-text">
              <h1 className="sec-title">Serviços na nossa plataforma</h1>
              <p>
                <strong>Para Ongs:</strong> divulgue seu projeto e encontre
                pessoas que desejem contribuir com a sua causa.
              </p>
              <p>
                <strong>Para Doadores:</strong> encontre instituições que
                trabalham em prol de causas que você acredita e colabore.
              </p>
            </div>
          </div>
          <div className="lp-sec9--2-wrapper2">
            <img src={AdminScreenImage} alt="Tela de administração" />
          </div>
          <div className="lp-sec9--2-wrapper3">
            <div className="lp-sec9--2-wrapper3--text">
              <h3 className="subsec-title">
                Inscreva sua ONG <br />
                Clique aqui para ser um futuro Doador
              </h3>
              <p className="subsec-paragraph">
                Na nosso plataforma analisaremos as ONG Cadastradas em nosso
                site.
              </p>
              <p className="subsec-paragraph">
                Ajudaremos as instituições que desejam se digitalizar por meio
                da nossa plataforma.
              </p>
            </div>
            <img src={CampaignImage} alt="Tela campanha" /> 
          </div>
        </div>
      </section>
      <section id="interacao" className={`lp-sec10--2 ${slideColor}`}>
        <div className="lp-sec10--2-wrapper-1">
          <h2 className="sec-title--2 sec-title--2-light">
            <img src={PeopleIcon} alt="Ícone Pessoas" />
            Nosso time
          </h2>
          <div className="lp-sec10--2-slider">
            <Carousel
              showArrows={false}
              renderIndicator={renderIndicator}
              showStatus={false}
              showThumbs={false}
              onChange={handleClickIndicator}
              swipeable={true}
              emulateTouch={true}
            >
              {[
                <SlideItem title="Coordenadores" slideClass="slide--0" />,
                <SlideItem title="Design" slideClass="slide--1" />,
                <SlideItem title="Desenvolvimento" slideClass="slide--2" />,
              ]}
            </Carousel>
          </div>
        </div>
      </section>
      <section className="lp-sec11--2">
        <div className="lp-sec11--2--bg">
          <svg
            width="1440"
            height="623"
            viewBox="0 0 1440 623"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_2514_3555)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M-15.5293 169.841V622.836H1503.47L1503.47 169.841C1503.47 169.841 787.656 135.384 438.697 27.3749C89.7374 -80.6345 -15.5293 169.841 -15.5293 169.841Z"
                fill="#D75C5F"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_2514_3555"
                x="-19.5293"
                y="0.337891"
                width="1527"
                height="630.498"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2514_3555"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2514_3555"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>

        <div className="lp-sec11--2-container">
          {!subscribeMessage ? (
            <>
              <div className="lp-sec11--2-wrapper1">
                <img src={EnvelopeIcon} alt="Fique por dentro das novidades" />
              </div>
              <div className="lp-sec11--2-wrapper2">
                <h1 className="lp-sec11--2-title">
                  Fique por dentro das novidades da <span>#DoarBem</span>
                </h1>
                <div className="lp-sec11--2-form-container">
                  <h2 className="lp-sec11--2-subtitle">
                    Recebe notícias das nossas <strong>vagas</strong>, do{" "}
                    <strong>mundo</strong> da <strong>doação</strong> e como
                    entrar nessa iniciativa enriquecedora.
                  </h2>
                  <MailchimpSubscribe
                    url={postUrl}
                    render={({ subscribe, status, message }) => (
                      <NewsletterForm
                        subscribe={subscribe}
                        status={status}
                        message={message}
                        successForm={successForm}
                      />
                    )}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="success-subscribe-container">
              <div className="success-subscribe-message">
                <WorldIcon />
                <h2>Uhu! Obrigada por estar conosco nesta jornada.</h2>
                <br />
                <strong>Aguarde-nos!</strong>
              </div>
              <div className="success-subscribe-image">
                <img
                  src={SubscribeImage}
                  alt="Inscrição realizada com sucesso"
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default BodyPartTwo;
