import React, { InputHTMLAttributes } from "react";
import { MdCheck } from "react-icons/md";

import "./formcirclecheckbox.scss";
interface FormCircleCheckboxProps
  extends InputHTMLAttributes<HTMLInputElement> {
  handleClick: any;
  checked: boolean;
  handleCheck: () => void;
}

const FormCircleCheckbox: React.FC<FormCircleCheckboxProps> = ({
  children,
  handleClick,
  handleCheck,
  checked,
  ...rest
}) => {
  return (
    <label className="form-circle-checkbox" onClick={handleCheck}>
      <div className={checked ? "checked" : ""} onClick={handleClick}>
        <MdCheck />
      </div>
      <input {...rest} type="hidden"/>
      <span>{children}</span>
    </label>
  );
};

export default FormCircleCheckbox;
