import { useEffect } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import Home from "./pages/Home";
import BodyPartTwo from "./components/BodyPartTwo";
import Cookie from "./components/Cookie";


function App() {

  useEffect(() => {
    document.title = "DoarBem"
  }, [])

  return (
    <div id="main">
      <Header />
      <Home />
      <BodyPartTwo />
      <Footer />
      <Cookie />
    </div>
  );
}

export default App;
