import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import AjudeImage from "../../assets/images/ajude.svg";
import Logo from "../../assets/images/logo-form.svg";
import FormInput from "../FormInput";
import Number from "../FormInput/Number";
import { MdClose } from "react-icons/md";

import "./formmodal.scss";

import FormCheckbox from "../FormCheckbox";
import FormCircleCheckbox from "../FormCheckbox/FormCircleCheckbox";
import TermContainer from "../TermContainer";
import { formApi, getCities } from "../../services/api";

interface FormModalProps {
  isOpen: boolean;
  toggleModal: (open?: boolean) => void;
}

interface IBGEUFResponse {
  id: number;
  sigla: string;
  nome: string;
}

interface IBGECityResponse {
  id: number;
  nome: string;
}

interface FormData {
  nome: string;
  email: string;
  telefone: string;
  cidade: string;
  estado: string;
  tipoLead: string;
  termosAceitos: boolean;
}

const FormModal: React.FC<FormModalProps> = ({ isOpen, toggleModal }) => {
  const backgroundRef = useRef<HTMLDivElement>(null);
  const [ufs, setUfs] = useState<IBGEUFResponse[]>([]);
  const [cities, setCities] = useState<IBGECityResponse[]>([]);
  const [selectedUf, setSelectedUf] = useState("0");
  const [selectedCity, setSelectedCity] = useState("0");
  const [openModalTerm, setOpenModalTerm] = useState(false);
  const [checked, setChecked] = useState(false);
  const [formSend, setFormSend] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState<FormData>({
    nome: "",
    email: "",
    telefone: "",
    cidade: "",
    estado: "",
    tipoLead: "DOADOR",
    termosAceitos: false,
  });

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      const options = {
        url: `landingpage`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: value,
      };

      formApi.get("/landing-page").then((response) => {
        const users = response.data.data
        users.forEach(userEmail => {
          if (userEmail.email === options.data.email) {
            setFormSend(false)
            alert("usuário já cadastrado!")
          } 
        })
      })

      formApi.post("/landing-page", options.data).then((response) => {
        if (response.status === 201) {
          setFormSend(true);
        }
      });
    },
    [value]
  );

  const handleCheck = useCallback(() => {
    setChecked(!checked);
  }, [checked, setChecked]);

  const handleFocus = useCallback(() => {
    setFocus(!focus);
  }, [focus, setFocus]);

  const handleClose = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  const handleClickBackground = useCallback(
    (event: any) => {
      if (event.target === backgroundRef.current) {
        toggleModal();
      }
    },
    [toggleModal]
  );

  useEffect(() => {
    if (selectedUf === "0") {
      return;
    }
    getCities.get(`${selectedUf}/municipios`).then((response) => {
      setCities(response.data);
    });
  }, [selectedUf]);

  useEffect(() => {
    getCities.get("").then((response) => {
      setUfs(response.data);
    });
  }, [selectedUf]);

  const handleSelectUf = (event: ChangeEvent<HTMLSelectElement>) => {
    const uf = event.target.value;
    setSelectedUf(uf);
    setValue({ ...value, estado: uf });
  };

  const handleSelectCity = (event: ChangeEvent<HTMLSelectElement>) => {
    const city = event.target.value;
    setSelectedCity(city);
    setValue({ ...value, cidade: city });
  };

  const handleBack = () => {
    setFormSend(false);
    setValue({
      nome: "",
      email: "",
      telefone: "",
      cidade: "",
      estado: "",
      tipoLead: "DOADOR",
      termosAceitos: false,
    });
  };

  return (
    <>
      {openModalTerm && (
        <TermContainer
          onClose={() => {
            setOpenModalTerm(false);
          }}
        />
      )}
      {isOpen && (
        <div
          className="form-modal--bg"
          ref={backgroundRef}
          onClick={handleClickBackground}
        >
          <div className="form-modal--container">
            <div className="form-modal--wrapper1">
              <h1>Embarque nessa</h1>
              <p>
                Sua participação pode ajudar a escrever a história de milhares
                de pessoas
              </p>
              <h2>Estamos esperando por você! </h2>

              <img src={AjudeImage} alt="Ajude" />
            </div>
            {formSend ? (
              <div className="form-modal--wrapper2">
                <p>Cadastro realizado com sucesso</p>
                <button
                  onClick={() => handleBack()}
                  className="form-modal--button"
                >
                  Realizar outro cadastro
                </button>
              </div>
            ) : (
              <div className="form-modal--wrapper2">
                <button className="form-modal--close" onClick={handleClose}>
                  <MdClose />
                </button>
                <img src={Logo} alt="Doar Bem" />
                <h4>Preencha o nosso formulário Doarbenzinhos(as)</h4>
                <div>
                  <form onSubmit={handleSubmit} method="post">
                    <FormInput
                      handleChange={(e) =>
                        setValue({ ...value, nome: e.target.value })
                      }
                      labelText="Seu Nome"
                      type="text"
                    />
                    <FormInput
                      handleChange={(e) =>
                        setValue({ ...value, email: e.target.value })
                      }
                      labelText="Seu E-mail"
                      type="email"
                    />
                    <Number
                      handleChange={(e) =>
                        setValue({ ...value, telefone: e.target.value })
                      }
                      onMouseLeave={(e) =>
                        setValue({ ...value, telefone: e.currentTarget.value })
                      }
                      labelText="Seu Telefone"
                      type="text"
                      mask="phone"
                    />
                    <label className="form-modal--label-select">
                      <p>Seu Estado</p>
                      <select
                        required
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                        name="uf"
                        id="uf"
                        onChange={handleSelectUf}
                        className={focus ? "focus" : ""}
                      >
                        <option value="0">Selecione uma UF</option>
                        {ufs.map((uf) => (
                          <option value={uf.sigla}>{uf.nome}</option>
                        ))}
                      </select>
                    </label>
                    <label className="form-modal--label-select">
                      <p>Cidade</p>
                      <select
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                        name="City"
                        required
                        id="City"
                        value={selectedCity}
                        onChange={handleSelectCity}
                        className={focus ? "focus" : ""}
                      >
                        <option value="0">Selecione uma cidade</option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.nome}>
                            {city.nome}
                          </option>
                        ))}
                      </select>
                    </label>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <FormCircleCheckbox
                        checked={checked}
                        handleCheck={handleCheck}
                        handleClick={() =>
                          setValue({ ...value, tipoLead: "ONG" })
                        }
                      >
                        <p>
                          Sou <strong>ONG</strong>
                        </p>
                      </FormCircleCheckbox>
                      <FormCircleCheckbox
                        checked={!checked}
                        handleCheck={handleCheck}
                        handleClick={() =>
                          setValue({ ...value, tipoLead: "DOADOR" })
                        }
                      >
                        <p>
                          Sou <strong>DOADOR</strong>
                        </p>
                      </FormCircleCheckbox>
                    </div>
                    <div className="form-modal--terms">
                      <h6>Termos</h6>
                      <div className="form-modal--term">
                        <FormCheckbox
                          handleChange={() =>
                            setValue({ ...value, termosAceitos: true })
                          }
                          labelText=""
                        />
                        <button onClick={() => setOpenModalTerm(true)}>
                          <p> Eu li e aceito os termos de privacidade</p>
                        </button>
                      </div>
                    </div>
                    {value.cidade === "" ||
                    value.estado === "" ||
                    value.termosAceitos === false ? (
                      <button disabled className="form-modal--button--disabled">
                        INSCREVA-SE
                      </button>
                    ) : (
                      <button className="form-modal--button">
                        INSCREVA-SE
                      </button>
                    )}
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FormModal;
