import React, { useCallback, useEffect, useState } from 'react';
import NewsletterInput from './NewsletterInput';

interface SubscribeProps{
    EMAIL: string;
    MERGE1: string;
}

interface NewsletterFormProps{
    subscribe(data: SubscribeProps): void; 
    status: "error" | "success" | "sending" | null;
    message: string | Error | null;
    successForm(data: any): void;
}

const NewsletterForm: React.FC<NewsletterFormProps> = ({subscribe, status, message, successForm}) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const handleSubmit = useCallback((e)=>{
        e.preventDefault();

        if(!name){
            setNameError(true);
        }else{
            setNameError(false);
        }

        if(!email){
            setEmailError(true);
        }else{
            setEmailError(false);
        }

        email &&
        name &&
        email.indexOf("@") > -1 &&
        subscribe({
            EMAIL: email,
            MERGE1: name,
        });

    },[email, name, subscribe]);

    useEffect(()=>{

        switch(status){
            case "success":
                setName('');
                setEmail('');
                successForm(true)
            break;
            case "error":
                console.log(message)
            break;
        }

    },[status, message, successForm]);

    return (
        <form onSubmit={handleSubmit}>
            <NewsletterInput
                label="Nome *"
                type="text"
                name="name"
                value={name}
                hasError={nameError}
                handleChange={(e)=> {setName(e.target.value)} }
            />
            <NewsletterInput
                label="E-mail *"
                type="email"
                name="email"
                value={email}
                hasError={emailError}
                handleChange={(e)=> {setEmail(e.target.value)} }
            />
            <div className='lp-sec11--2-form-control'>
                <button className='lp-sec11--2-btn'>
                    Inscreva-se na nossa newsletter
                </button>
            </div>
        </form>
    );
}

export default NewsletterForm;