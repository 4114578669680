import React, { useEffect, useState } from "react";
import logo from "../../core/assets/icons/logo.png";
import classes from "./Header.module.scss";
import face from "../../core/assets/icons/facebook_red.svg";
import insta from "../../core/assets/icons/insta_red.svg";
import linkedin from "../../core/assets/icons/linkedin_red.svg";
import sanduiche from "../../core/assets/icons/sanduiche.svg";
import closeMenu from "../../core/assets/icons/x.svg";

export const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [windowSize, setWindowSize] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowSize >= 1024 && openMenu) {
      setOpenMenu(false);
    }
  }, [windowSize, openMenu]);

  const openMenuHandler = () => {
    setOpenMenu((p) => !p);
  };

  return (
    <header className={classes.Header__content}>
      <div className={classes.Header__img}>
        <a href="#doarbemhome">
          <img src={logo} alt="logo da Doar Bem" id="logo" />
        </a>
      </div>
      <div className={classes.Header__nav}>
        {window.innerWidth >= 1024 ? (
          <nav>
            <ul className={classes.Header__nav__ul}>
              <li>
                <a href="#somosmovidos">Projeto DoarBem</a>
              </li>
              <li>
                <a href="#propositosevalores">Propósito e Valores</a>
              </li>
              <li>
                <a href="#objetivos">Objetivos e Desafios</a>
              </li>
              <li>
                <a href="#servicos">Nossas Soluções</a>
              </li>
              <li>
                <a href="#interacao">Conheça a Doarbem</a>
              </li>
            </ul>
          </nav>
        ) : (
          <div className={classes.Header__sanduiche}>
            <button
              className={classes.Header__sanduiche__button}
              onClick={openMenuHandler}
            >
              {openMenu ? (
                <img
                  src={closeMenu}
                  className={classes.Header__sanduiche__img}
                  alt="imagem sanduiche do Header Mobile"
                />
              ) : (
                <img
                  src={sanduiche}
                  className={classes.Header__sanduiche__img}
                  alt="imagem sanduiche do Header Mobile"
                />
              )}
            </button>
            {openMenu && (
              <div className={classes.Header__sanduiche__nav}>
                <nav>
                  <div className={classes.Header__sanduiche__logo}>
                    <img src={logo} alt="logo da Doar Bem" />
                  </div>
                  <ul>
                    <li>
                      <a href="#somosmovidos">
                        Projeto <span>DoarBem</span>
                      </a>
                    </li>
                    <li>
                      <a href="#propositosevalores">
                        <span>Propósito</span> e Valores
                      </a>
                    </li>
                    <li>
                      <a href="#objetivos">
                        <span>Objetivos</span> e Desafios
                      </a>
                    </li>
                    <li>
                      <a href="#servicos">
                        Nossas <span>Soluções</span>
                      </a>
                    </li>
                    <li>
                      <a href="#interacao">
                        Conheça a <span>Doarbem</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        )}
        <div
          className={
            window.innerWidth >= 1024
              ? classes.Header__nav__redes
              : classes.Header__nav__redes__tablet
          }
        >
          <a
            href="https://www.linkedin.com/in/projeto-doar-bem/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="icone do aedin" width={56} />
          </a>
          <a
            href="https://www.facebook.com/DoarBem-111930133986791"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={face} alt="icone do facebook" width={56} />
          </a>
          <a
            href="https://www.instagram.com/doar_bem/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={insta} alt="icone do instagram" width={52} />
          </a>
        </div>
      </div>
    </header>
  );
};
